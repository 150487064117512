import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import { CTAFormPopUp } from 'components/CTAForm';
import ScrollToTopButton from 'components/ScrollToTopButton';
import FaqContainer from 'components/FaqContainer';
import HelpContainer from 'components/HelpContainer';


const SupportPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO meta={data.supportYaml.metaTags} title={data.supportYaml.title} titleTemplate="%s" />
      <Hero
        data={data.supportYaml.hero}
      />
      <HelpContainer data={data.supportYaml} />
      <FaqContainer data={data.supportYaml.faq} />
      <CTAFormPopUp />
      <ZipGateContainer location={location}/>
      <ScrollToTopButton />
    </Layout>
  );
};
  
export default SupportPage;
  
export const pageQuery = graphql`
  query SupportPageQuery {
    supportYaml {
      id
      title
      metaTags {
        name
        content
      }
      hero {
        size
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          } 
        }
        heroImagePosition
      }
      callUs {
        title
        items {
          title
          description
        }
      }
      emailUs {
        title
        submitButton
        success {
          title
          description
          logo {
            publicURL
          }
        }
        disclaimer
        submissionError
      }
      helpfulLinks {
        title
        items {
          title
          link
          icon
          newTab
        }
      }
      faq {
        title
        description
        faqs {
          question
          answer
          tag
        }
      }
    }
  }
`;
