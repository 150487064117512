import React, { Component, Fragment } from 'react';
import { findAll } from 'highlight-words-core';
import lunr from 'lunr';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemState
} from 'react-accessible-accordion';
import AnimateHeight from 'react-animate-height';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import { TextInput } from 'components/Shared/Form/FormInput/FormInput';
import IconClear from 'static/images/icons/clear-x.inline.svg';
import IconSearch from 'static/images/icons/search.inline.svg';
import './FaqContainer.scss';


class FAQContainer extends Component {

  constructor() {
    super();
    this.idx = null;
    this.tags = [ "All", "Technical Support", "Member Services" ];
    this.timeout = null;
    this.state = {
      search: "",
      searchResults: [],
      tagFilter: ["All"]
    };
  }
  
  componentDidMount() {
    const { data } = this.props;
    const reTags = new RegExp("<[^>]+?>", "gm");
    this.idx = lunr(function () {
      this.metadataWhitelist = ['position'];
      this.field('question');
      this.field('answer');
      data.faqs.map(item => { 
        this.add({
          question: item.question.replace(reTags, m => ' '.repeat(m.length)),
          answer: item.answer.replace(reTags, m => ' '.repeat(m.length)),
          id: item.question
        })
      });
    });
  }

  doSearch = () => {
    const results = this.idx.search(this.state.search.replace(':', ''));
    const searchResults = [];
    results.forEach(match => {
      const faqItem = Object.assign({}, this.props.data.faqs.find(item => item.question === match.ref));
      faqItem.highlight = {}
      const metadata = match.matchData.metadata;
      // eslint-disable-next-line no-unused-vars
      for (let term in metadata) {
        const termData = metadata[term];
        // eslint-disable-next-line no-unused-vars
        for (let field in termData) {
          const fieldData = termData[field];
          faqItem.highlight[field] = faqItem.highlight[field] || [];
          fieldData.position.forEach(([start, len]) => {
            faqItem.highlight[field].push(faqItem[field].slice(start, start+len));
          });
        }
      }
      searchResults.push(faqItem);
    });
    this.setState({ searchResults: searchResults });
  }

  onChange = value => {
    this.setState({ search: value });
    if(!this.timeout) {
      this.timeout = setTimeout(
        () => {
          this.timeout = null;
          this.doSearch();
        }, 1000
      )
    }
  }

  getHighlightedText = (searchWords, textToHighlight) => {
    const chunks = findAll({
      autoEscape: true,
      caseSensitive: false,
      sanitize: text => text.replace(/<[^>]+?>/gm, m => ' '.repeat(m.length)),
      searchWords,
      textToHighlight
    });
    const parts = chunks.map((chunk) => {
      const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start)
      if (chunk.highlight) {
        return `<mark>${text}</mark>`
      } else {
        return text
      }
    });
    return parts.join('');
  }

  filter = (value, checked) => {
    let tagFilter = this.state.tagFilter;
    if(value === "All") {
      tagFilter = ["All"];
    } else {
      if(checked) {
        tagFilter.push(value);
        value = "All";
      }
      const index = tagFilter.indexOf(value);
      if(index !== -1) {
        tagFilter.splice(index, 1);
      }
      if(!tagFilter.length) {
        tagFilter.push("All");
      }
    }
    this.setState({ tagFilter: tagFilter });
  } 

  render() {
    const { data } = this.props;
    const { search, searchResults, tagFilter } = this.state;
    let itemsToShow = search ? searchResults : data.faqs;
    if(!tagFilter.includes("All")) {
      itemsToShow = itemsToShow.filter(faq => faq.tag && tagFilter.includes(faq.tag));
    }
    return (
      <section id="faqs" className="aaa-faq">
        <ContentBox>
          <h2 className="aaa-faq__title">{data.title}</h2>
          <div className="aaa-faq__search">
            <div className="aaa-faq__search__input-box">
              <TextInput
                data={{ placeholder: "Search answers" }}
                input={{ 
                  value: this.state.search, 
                  onChange: e => this.onChange(e.target.value) 
                }} 
              />
              <IconSearch className="aaa-faq__search__icon" />
              { search ? (
                <IconClear
                  className="aaa-faq__search__clear"
                  onClick={() => this.onChange("")} 
                />
              ) : null }
              <div className="aaa-faq__search__hint">
                use keywords, ex: false alarm
              </div>
            </div>
            <div className="aaa-faq__search__tags-box">
              { this.tags.map((tag, i) => {
                const key = `search_tag_${i}`;
                const checked = tagFilter.includes(tag);
                return (
                  <div 
                    className="aaa-faq__search__tag"
                    key={key}
                  >
                    <input 
                      id={key} 
                      name={key} 
                      type="checkbox"
                      value={tag} 
                      onChange={e => this.filter(e.target.value, e.target.checked)}
                      checked={checked}
                    />
                    <label 
                      htmlFor={key}
                      className={ checked ? "checked" : "" }
                    >
                      <span>{tag}</span>
                    </label>
                  </div>
                );
              }) }
            </div>
          </div>
          { itemsToShow.length ? (
            <Fragment>
              <Accordion 
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                preExpanded={ search.length ? itemsToShow.map( item => item.question ) : []}
              >
                {itemsToShow.map( (item) => (
                  <AccordionItem collapsed="false" key={item.question} uuid={item.question}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        { item.highlight && item.highlight.question &&
                        <span className="aaa-faq__question"
                          dangerouslySetInnerHTML={{
                          __html: this.getHighlightedText(item.highlight.question, item.question)
                          }}
                        />
                        ||
                        <span className="aaa-faq__question">
                            {item.question}
                        </span>}
                        <span className="aaa-faq__caret">&rsaquo;</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemState>
                      {({ expanded }) => (
                        <div className="accordion__panel">
                          <AnimateHeight
                            duration={300}
                            height={expanded ? 'auto' : 0}
                          >
                            { item.highlight && item.highlight.answer &&
                            <div className="aaa-faq__answer"
                              dangerouslySetInnerHTML={{
                                __html: this.getHighlightedText(item.highlight.answer, item.answer)
                              }}
                            />
                            || <div className="aaa-faq__answer"
                              dangerouslySetInnerHTML={{
                                __html: item.answer
                              }}
                            />}
                            <SeparatorWithGradient />
                          </AnimateHeight>
                        </div>
                      )}
                    </AccordionItemState>
                  </AccordionItem>
                ))}
              </Accordion>
              <div 
                className="aaa-faq__description"
                dangerouslySetInnerHTML={{
                  __html: data.description
                }}
              />
            </Fragment>
          ) : (
            <div className="aaa-faq__search__no-results">
              <h3>No matches found for <b>“{search}”</b></h3>
              <p>
                Check spelling, try another search term,<br/>
                or <a href="#" onClick={(e) => {
                  e.preventDefault(); 
                  this.onChange("");}}
                >reset search</a> to review all FAQs.
              </p>
            </div>
          )}
        </ContentBox>
      </section>
    )
  }
}

export default FAQContainer;